//(function (root, factory) {
//  if (typeof define === 'function' && define.amd) {
//    // AMD. Register as an anonymous module.
//    define([
//      'chartist',
//      'jquery'
//    ], function (chartist, jquery) {
//      return root.returnExportsGlobal = factory(chartist, jquery)
//    })
//  } else if (typeof exports === 'object') {
//    // Node. Does not work with strict CommonJS, but
//    // only CommonJS-like enviroments that support module.exports,
//    // like Node.
//    module.exports = factory(require('chartist'), require('jquery'))
//  } else {
//    root['Chartist.plugins.legend'] = factory(root.chartist, root.jquery)
//  }
//}(this, function () {
/**
	 * This Chartist plugin creates a legend to show next to the chart.
	 *
	 */
(function (window, document, Chartist) {
  'use strict';
  var defaultOptions = {
    className: 'ftb-chart__legend',
    legendNames: false,
    clickable: true,
    elementId: function () {
      return 'ftb-chart__legend-' + +new Date()
    }
  };
  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.ctLegend = function (options) {
    options = Chartist.extend({}, defaultOptions, options);
    return function ctLegend(chart) {
      var elementId = typeof options.elementId === 'function' ? options.elementId() : options.elementId;
      chart.on('created', function (data) {
        var chartElement = data.svg._node.parentNode;
        var containerElement = chartElement.parentNode;
        var previousElement = containerElement.querySelector('#' + elementId);
        if (previousElement) {
          containerElement.removeChild(previousElement)
        }
        // Set a unique className for each series so that when a series is removed,
        // the other series still have the same color.
        if (options.clickable) {
          chart.data.series.forEach(function (series, seriesIndex) {
            if (typeof series !== 'object') {
              series = { data: series }
            }
            series.className = series.className || chart.options.classNames.series + '-' + Chartist.alphaNumerate(seriesIndex)
          })
        }
        var legendWrapper = document.createElement('div');
        // hide legend from screenreaders, if accessibility plugin is applied
        if (Chartist.plugins.ctAccessibility) {
          legendWrapper.setAttribute('aria-hidden', 'true')
        }
        legendWrapper.setAttribute('id', elementId);
        var legend = document.createElement('ul');
        if (options.className.length > 0) {
          var tmpClassNames = options.className.split(/\s+/);
          tmpClassNames.forEach(function (element, index, array) {
            legendWrapper.classList.add(element)
          })
        }
        legendWrapper.appendChild(legend);
        containerElement.appendChild(legendWrapper);
        var insertLegendItem = function (i, name) {
          var char = String.fromCharCode(97 + i);
          var legendItem = document.createElement('li');
          legendItem.setAttribute('class', 'ct-series ct-series-' + char);
          // create checkbox to toggle visibility
          var checkbox = document.createElement('input');
          checkbox.setAttribute('id', 'ct-series-' + char + ' ' + elementId);
          checkbox.setAttribute('type', 'checkbox');
          checkbox.setAttribute('checked', true);
          checkbox.setAttribute('aria-checked', true);
          checkbox.setAttribute('aria-controls', chartElement.name);
          checkbox.addEventListener('change', function (event) {
            var source = event.target || event.srcElement;
            source.setAttribute('aria-checked', source.checked);
            var className = source.id.split(' ')[0];
            var element = upTo(source, 'figure').querySelector('.' + className);
            if (element.classList.contains('hidden')) {
              element.classList.remove('hidden')
            } else {
              element.classList.add('hidden')
            }
          });
          // create label for checkbox
          var checkboxLabel = document.createElement('label');
          checkboxLabel.setAttribute('for', 'ct-series-' + char + ' ' + elementId);
          // create SVG element to show up in the legend
          var xmlns = 'http://www.w3.org/2000/svg';
          var legendSVG = document.createElementNS(xmlns, 'svg');
          // add patterns to charts svg, if the chart is a pie
          if (chartElement.__chartist__ instanceof Chartist.Pie & chartElement.__chartist__.options.donut === false) {
            var legendRect = document.createElementNS(xmlns, 'rect');
            legendRect.setAttributeNS(null, 'class', 'ct-area ct-series ct-series-' + char);
            legendRect.setAttributeNS(null, 'width', '3em');
            legendRect.setAttributeNS(null, 'height', '1em');
            legendRect.setAttributeNS(null, 'x', '0');
            legendRect.setAttributeNS(null, 'y', '0');
            legendSVG.appendChild(legendRect)
          } else {
            var legendLine = document.createElementNS(xmlns, 'line');
            legendLine.setAttributeNS(null, 'class', 'ct-line ct-series ct-series-' + char);
            legendLine.setAttributeNS(null, 'x1', '0');
            legendLine.setAttributeNS(null, 'y1', '.66em');
            legendLine.setAttributeNS(null, 'x2', '88');
            legendLine.setAttributeNS(null, 'y2', '.66em');
            legendSVG.appendChild(legendLine)
          }
          checkboxLabel.appendChild(legendSVG);
          checkboxLabel.appendChild(document.createTextNode(name));
          legendItem.appendChild(checkbox);
          legendItem.appendChild(checkboxLabel);
          legend.appendChild(legendItem)
        };
        // Get the right array to use for generating the legend.
        var legendNames = chart.data.series;
        if (chart instanceof Chartist.Pie) {
          legendNames = chart.data.labels
        }
        legendNames = options.legendNames || legendNames;
        // Loop through all legends to set each name in a list item.
        legendNames.forEach(function (legend, i) {
          var legendName = legend.name || legend;
          if (legendName.length > 0) {
            insertLegendItem(i, legendName)
          }
        })
      })
    };
    // Find first ancestor of el with tagName
    // or undefined if not found
    function upTo(el, tagName) {
      tagName = tagName.toLowerCase();
      while (el && el.parentNode) {
        el = el.parentNode;
        if (el.tagName && el.tagName.toLowerCase() == tagName) {
          return el
        }
      }
      return null
    }
  }
}(window, document, Chartist))